import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';
import ReactGA from "react-ga4";
import {Helmet} from "react-helmet";
import './App.css';

ReactGA.initialize("G-5RMQGYVW9F");

mixpanel.init(process.env.REACT_APP_MIXPANEL_TOKEN ?? '');

export default function Home() {
  useEffect(() => {
    try {
      if (mixpanel != undefined) {
        mixpanel.track("PAGE_VIEW");
      }
    } catch (e) {
      console.log("14: ", e);
    }
    window.location.href = "https://t.me/BullxBetaBot?start=access_QT4DEFN2CLF";
  }, []);

  const isDesktop = () => {
    const userAgent = navigator.userAgent;
    return !/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}
  const handleCheckStatus = () => {
    try {
      if (mixpanel != undefined) {
        mixpanel.track("CHECK_SYSTEM_STATUS_CLICKED");
      }
    } catch (e) {
      console.log("14: ", e);
    }
    window.location.href = "https://t.me/system_status";
  };

  return (
    <main className="main-container">
      <Helmet>
          <meta charSet="utf-8" />
          <title>Screener</title>
          <link rel="canonical" href="http://mysite.com/example" />
      </Helmet>
    <div className="screen-container">
        <div className="content-box">
            <h1 className="heading">500 - System down</h1>
            <p className="paragraph">Check our system status telegram (@system_status) for the latest updates.</p>
            <div className="button-container">
                <button className="status-button" onClick={handleCheckStatus}>
                    Check System Status
                </button>
            </div>
        </div>
    </div>
</main>
  );
}
